import React, { Component } from "react";
import {Button, Grid, List, Card, Search, Container} from "semantic-ui-react";

export default class S2STokenSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchResults: props.tokens,
            searchValue: ""
        };

        this.generateListItem = this.generateListItem.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.updateSearchResults = this.updateSearchResults.bind(this);
    }

    generateListItem = tokenId => {
        console.log("S2STokenSelector.generateListItem", tokenId);
        if (tokenId) {
            let token = this.props.tokens[tokenId];
            if (token) {
                return (
                    <List.Item
                        onClick={() => this.props.switchToken(tokenId)}
                        key={tokenId}
                        active={tokenId === this.props.selectedToken.id}
                    >
                        <List.Content>
                            <Card>
                                <Card.Content>
                                    <Card.Description>
                                        <h5>{token.name}</h5>
                                        {token.description}
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        </List.Content>
                    </List.Item>
                );
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tokens !== this.props.tokens || this.props.selectedToken !== prevProps.selectedToken) {
            console.log("S2STokenSelector.componentDidUpdate new tokens:", this.props.tokens);
            this.handleSearchChange({}, {value: this.state.searchValue});
        }
    }

    updateSearchResults() {
        let searchResults = {};

        for (const tokenId of Object.keys(this.props.tokens)) {
            const token = this.props.tokens[tokenId];
            if ("" === this.state.searchValue || (token && token.name && token.name.toLowerCase().includes(this.state.searchValue.toLowerCase()))) {
                searchResults[token.id] = token;
            }
        }
        return searchResults;
    }

    handleSearchChange = (event, {value}) => {
        this.setState({searchValue: value}, () => {
            this.setState({searchResults: this.updateSearchResults()}, () => {
                console.log(this.props.tokens, this.state.searchValue, this.state.searchResults);
            });
        });
    }

    onResultSelect = (event, props) => {
        console.log(event.target, props);
    }

    render() {
        return (
            <Grid>
                <Grid.Column>
                    <Grid.Row width={16}>
                        <Button onClick={this.props.createToken}>Create Token</Button>
                    </Grid.Row>
                    <br />
                    <Grid.Row>
                        <Search
                            results={this.state.searchResults}
                            onResultSelect={this.onResultSelect}
                            onSearchChange={this.handleSearchChange}
                            value={this.state.searchValue}
                            open={false}
                        />
                    </Grid.Row>
                    <Container style={{maxHeight: 614, overflowY: "auto"}}>
                        {Object.keys(this.props.tokens).length > 0 ?
                            <List>
                                {Object.keys(this.state.searchResults).map(this.generateListItem)}
                            </List> :
                            <Card>
                                <Card.Content>
                                    <Card.Description>
                                        <h5>No Tokens</h5>
                                        Create a token using the button above.
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        }
                    </Container>
                </Grid.Column>
            </Grid>
        );
    }
}

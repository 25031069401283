import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {Grid, Form, Button, Divider, Header, Checkbox, Input} from "semantic-ui-react";
import RouteDataProvider from "../../../Services/RouteDataProvider";
import AuthDataProvider from "../../../Services/AuthDataProvider";

class RouteMasterDetailView extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            routes: [],
            permissions: [],
            permissionAdditions: [],
            action: "",
            description: "",
            roles: [],
            s2sAppId: "",
            s2sJwtLocation: "",
            s2sKid: "",
            s2sSecret: "",
            upstreamDomain: "",
            upstreamPath: "",
            upstreamTimeout: 0,
            upstreamId: "",
            version: "",
            s2sEnabled: false,
            s2sForcePost: false,
            prefillSelection: {},
            routeSelection: {}
        }

        AuthDataProvider.init({baseURL: process.env.SYNAPSE_CORE_URL}).then(() => {
            RouteDataProvider.init({baseURL: process.env.SYNAPSE_CORE_URL});
            this.getRoutes();
            this.getPermissions();
        });
    }

    getRoutes = (callback) => {
        RouteDataProvider.getRoutes()
            .then(resp => {
                resp.json()
                    .then(routes => {
                        let allRoutes = [];

                        routes.forEach(route => {
                            route.value = route["_id"];
                            route.label = `${route.description}: ${route.upstreamId} - ${route.action}`;

                            allRoutes.push(route)
                        });

                        this.setState({
                            routes: allRoutes
                        }, () => {
                            if(typeof callback === "function") {
                                callback();
                            }
                        });
                    });
            });
    }

    getPermissions = () => {
        AuthDataProvider.getAllPermissions().then(response => {
            console.log("getting permissions: ", response);
            const updatedPermissions = [];
            for (const permission of response) {
                updatedPermissions.push({value: permission.urn, label: permission.urn});
            }

            this.setState({permissions: updatedPermissions});
        });
    }

    handleRouteSelection = route => {
        this.setState({
            routeSelection: route
        });
    }

    prefillData = route => {
        this.state.routes.forEach(r => {
            if(r["_id"] === route["_id"]) {
                let uniqueRoles = new Set(r.roles);
                const uniqueRoleArray = []
                for (const role of Array.from(uniqueRoles).sort()) {
                    uniqueRoleArray.push({value: role, label: role});
                }

                this.setState({
                    prefillSelection: route,
                    action: r.action,
                    description: r.description,
                    s2sAppId: r.s2s.appId,
                    s2sJwtLocation: r.s2s.jwtLocation,
                    s2sKid: r.s2s.kid,
                    s2sSecret: r.s2s.secret,
                    upstreamDomain: r.upstream.domain,
                    upstreamPath: r.upstream.path,
                    upstreamTimeout: r.upstream.timeoutMs,
                    upstreamId: r.upstreamId,
                    version: r.version,
                    s2sEnabled: r.s2s.enabled,
                    s2sForcePost: r.s2s.forcePost,
                    permissionAdditions: uniqueRoleArray
                });
            }
        });
    }

    addRoute = () => {
        let userId = this.props.user.sub || null;
        let routeData = {
            action: this.state.action,
            description: this.state.description,
            roles: [],
            s2s: {
                appId: this.state.s2sAppId,
                enabled: this.state.s2sEnabled,
                forcePost: this.state.s2sForcePost,
                jwtLocation: this.state.s2sJwtLocation,
                kid: this.state.s2sKid,
                secret: this.state.s2sSecret
            },
            upstream: {
                domain: this.state.upstreamDomain,
                path: this.state.upstreamPath,
                timeoutMs: parseInt(this.state.upstreamTimeout)
            },
            upstreamId: this.state.upstreamId,
            version: this.state.version
        };

        if (this.state.s2sJwtLocation === "") {
            delete routeData.s2s.jwtLocation;
        }

        if(this.state.action === "") {
            this.props.toast("Error", "Action is a required field", "error");
        } else if (this.state.description === "") {
            this.props.toast("Error", "description is a required field", "error");
        } else if (this.state.upstreamId === "") {
            this.props.toast("Error", "upstreamId is a required field", "error");
        } else if (this.state.version === "") {
            this.props.toast("Error", "version is a required field", "error");
        } else if (this.state.domain === "") {
            this.props.toast("Error", "upstream domain is a required field", "error");
        } else if (this.state.path === "") {
            this.props.toast("Error", "upstream path is a required field", "error");
        } else if (this.state.timeoutMs === "") {
            this.props.toast("Error", "upstream timeoutMs is a required field", "error");
        } else {
            this.state.permissionAdditions.forEach(permission => {
                routeData.roles.push(permission.value);
            });

            RouteDataProvider.addRoute(userId, routeData).then(resp => {
                resp.json()
                    .then(payload => {
                        if (payload.error) {
                            this.props.toast("Error", payload.error, "error");
                        }

                        if (payload.message) {
                            this.props.toast("Message", payload.message, "info");
                        }

                        this.getRoutes(() => {
                            this.setState({
                                routeData: ""
                            });
                        });
                    });
            });
        }
    }

    deleteRoute = () => {
        let userId = null;
        let routeId = null;
        try {
            userId = this.props.user.sub || null;
            routeId = this.state.routeSelection.value || null;

            if (userId === null || routeId === null) {
                throw "No selected route and/or user can not be identified"
            }
            RouteDataProvider.removeRoute(userId, routeId).then(resp => {
                resp.json().then(payload => {
                    if (payload.message) {
                        this.props.toast("Message", payload.message, "info");
                    }
                    else if (payload.error) {
                        this.props.toast("Message", payload.error, "error");
                    }

                    this.getRoutes(() => {
                        this.setState({
                            routeSelection: {}
                        });
                    });
                });
            });
        } catch (error) {
            this.props.toast("Error", error, "error");
        }
    }

    render() {
        return (
            <Grid>
                <Grid.Column width={15} className="masterContainer">
                    <Grid>
                        <Grid.Row><Header as="h2"> Add/Edit Route</Header></Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={6}>
                                <Form>
                                    <Form.Field>
                                        <label>Select Route</label>
                                        <Select
                                            value={this.state.prefillSelection}
                                            options={this.state.routes}
                                            onChange={this.prefillData}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Form>
                                    <Form.Field
                                        label="Action"
                                        control={Input}
                                        required
                                        placeholder="Enter Action"
                                        value={this.state.action}
                                        onChange={(event, {value}) => this.setState({action: value})}
                                    />
                                    <Form.Field
                                        label="Version"
                                        control={Input}
                                        required
                                        placeholder="Enter Version"
                                        value={this.state.version}
                                        onChange={(event, {value}) => this.setState({version: value})}
                                    />
                                    <Form.Field
                                        label="Description"
                                        control={Input}
                                        required
                                        placeholder="Enter Description"
                                        value={this.state.description}
                                        onChange={(event, {value}) => this.setState({description: value})}
                                    />
                                    <Form.Field>
                                        <label>Roles</label>
                                        <Select
                                            isMulti
                                            placeholder="Select Roles"
                                            value={this.state.permissionAdditions}
                                            options={this.state.permissions}
                                            onChange={permissions => this.setState({permissionAdditions: permissions})}
                                        />
                                    </Form.Field>
                                </Form>
                            </Grid.Column>

                            <Grid.Column width={4}>
                                <Form>
                                    <Form.Field
                                        label="Upstream ID"
                                        required
                                        control={Input}
                                        placeholder="Enter Upstream ID"
                                        value={this.state.upstreamId}
                                        onChange={(event, {value}) => this.setState({upstreamId: value})}
                                    />
                                    <Form.Field
                                        label="Upstream Domain"
                                        required
                                        control={Input}
                                        value={this.state.upstreamDomain}
                                        placeholder="Enter Upstream Domain"
                                        onChange={(event, {value}) => this.setState({upstreamDomain: value})}
                                    />
                                    <Form.Field
                                        label="Upstream Path"
                                        required
                                        control={Input}
                                        value={this.state.upstreamPath}
                                        onChange={(event, {value}) => this.setState({upstreamPath: value})}
                                    />
                                    <Form.Field
                                        label="Upstream Timeout in milliseconds"
                                        required
                                        control={Input}
                                        value={this.state.upstreamTimeout}
                                        onChange={(event, {value}) => this.setState({upstreamTimeout: value})}
                                    />
                                </Form>
                            </Grid.Column>

                            <Grid.Column width={4}>
                                <Form>
                                    <Form.Field
                                        label="S2S App ID"
                                        control={Input}
                                        placeholder="Enter S2S App ID"
                                        value={this.state.s2sAppId}
                                        onChange={(event, {value}) => this.setState({s2sAppId: value})}
                                    />
                                    <Form.Field
                                        label="S2S JWT Location"
                                        control={Input}
                                        value={this.state.s2sJwtLocation}
                                        placeholder="Enter JWT Location"
                                        onChange={(event, {value}) => this.setState({s2sJwtLocation: value})}
                                    />
                                    <Form.Field
                                        label="S2S Key ID"
                                        control={Input}
                                        value={this.state.s2sKid}
                                        placeholder="Enter S2S Kid"
                                        onChange={(event, {value}) => this.setState({s2sKid: value})}
                                    />
                                    <Form.Field
                                        label="S2S Secret"
                                        control={Input}
                                        value={this.state.s2sSecret}
                                        placeholder="Enter S2S Secret"
                                        onChange={(event, {value}) => this.setState({s2sSecret: value})}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Form>
                                    <Form.Field
                                        label="S2S Enabled"
                                        control={Checkbox}
                                        toggle
                                        onClick={() => this.setState({s2sEnabled: !this.state.s2sEnabled})}
                                        checked={this.state.s2sEnabled}
                                    />
                                    <Form.Field
                                        label="S2S Force Post"
                                        control={Checkbox}
                                        toggle
                                        onClick={() => this.setState({s2sForcePost: !this.state.s2sForcePost})}
                                        checked={this.state.s2sForcePost}
                                    />
                                </Form>
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Button primary fluid onClick={this.addRoute}> Save </Button>
                            </Grid.Column>
                        </Grid.Row>

                        <Divider />
                        <Grid.Row><Header as="h2"> Delete Route</Header></Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={7}>
                                <Select
                                    value={this.state.routeSelection}
                                    options={this.state.routes}
                                    placeholder="Select route to delete"
                                    onChange={this.handleRouteSelection}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Button onClick={this.deleteRoute} negative fluid>Delete</Button>
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid>
        );
    }
}

RouteMasterDetailView.propTypes = {
    user: PropTypes.object.isRequired,
    toast: PropTypes.func.isRequired
};

export default RouteMasterDetailView;

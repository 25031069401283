import React, {Component} from "react";
import {Button, Grid, Header, Segment} from "semantic-ui-react";
import S2STokenSelector from "./S2STokenSelector";
import S2STokenDetailView from "./S2STokenDetailView";
import {v4} from "uuid";
import AuthDataProvider from "../../../Services/AuthDataProvider";
import jwt from "jsonwebtoken";

const VERSION_TO_ALG = {
    1: "HS512",
    2: "RS512"
};

export default class S2STokenGeneratorTab extends Component {
    constructor(props) {
        super(props);


        this.state = {
            selectedToken: {},
            tokens: {},
            jwt: "",
            tokenNotSelected: true
        }

        this.createToken = this.createToken.bind(this);
        this.updateToken = this.updateToken.bind(this);
        this.switchToken = this.switchToken.bind(this);
        this.updateSelectedToken = this.updateSelectedToken.bind(this);
        this.saveSelectedToken = this.saveSelectedToken.bind(this);
        this.deleteClicked = this.deleteClicked.bind(this);
        this.cloneClicked = this.cloneClicked.bind(this);
        this.generateJWT = this.generateJWT.bind(this);
        this.copyClicked = this.copyClicked.bind(this);
    }

    updateToken = token => {
        if (token.id) {
            this.state.tokens[id] = token;
        } else {
            let id = v4();
            token.id = id
            this.state.tokens[id] = token
        }
    }

    createToken = () => {
        let token = {
            id: v4(),
            ver: "",
            name: "",
            iss: "",
            kid: "",
            secret: "",
            endpoint: "",
            ttl: 0,
            payload: {},
            description: "",
            saved: false
        };
        console.log("(S2STokenGeneratorTab.createToken) Created token", token);
        let updatedTokens = this.state.tokens;
        updatedTokens[token.id] = token;
        this.setState({
            selectedToken: token,
            tokens: updatedTokens,
            tokenNotSelected: false
        });
    }

    componentDidMount() {
        AuthDataProvider.getS2STokens().then(tokens => {
            console.log("(S2STokenGeneratorTab.componentDidMount) tokens:", tokens);
            this.setState({tokens},
                () => console.log(this.state.tokens));
        });
    }

    updateSelectedToken = selectedToken => this.setState({ selectedToken });

    saveSelectedToken = async () => {
        console.log("(S2STokenGeneratorTab.saveSelectedToken) saving selected token");
        let {name, description, ttl} = this.state.selectedToken;
        let jwt = this.state.jwt;

        if (!name) {
            this.props.toast("Error", "Please provide a name for this token.", "error");
        } else if (!description) {
            this.props.toast("Error", "Please provide a description for this token.", "error");
        } else if (ttl < 0) {
            this.props.toast("Error", "Please set a valid TTL for this token.", "error");
        } else {
            if (!jwt) {
                await this.generateJWT();
                console.log("(S2STokenGeneratorTab.saveSelectedToken) no jwt generated; generating jwt");
            }

            if (!this.state.jwt) {
                this.props.toast("Error", "Could not generate a JWT.", "error");
                return;
            }

            let saveData = {
                _id: this.state.selectedToken.id,
                name: this.state.selectedToken.name,
                description: this.state.selectedToken.description,
                ttl: this.state.selectedToken.ttl,
                jwt: this.state.jwt
            };

            console.log("(S2STokenGeneratorTab.saveSelectedToken) saving data: ", saveData);

            await AuthDataProvider.putS2SToken(saveData).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            });
        }
    };

    deleteClicked = () => {
        let tokens = this.state.tokens;
        delete tokens[this.state.selectedToken.id];
        this.setState({tokens});
    }

    cloneClicked = () => {
        let {id, ...clone} = this.state.selectedToken;
        clone.id = v4();
        clone.name += `_${clone.id.slice(-8)}`;
        let tokens = this.state.tokens;
        tokens[clone.id] = clone;
        this.setState({selectedToken: clone, tokens});
    }

    copyClicked = () => {
        const copyhelper = document.createElement("input");
        copyhelper.className = 'copyhelper';
        document.body.appendChild(copyhelper);
        copyhelper.value = this.state.jwt;
        copyhelper.select();
        document.execCommand("copy");
        document.body.removeChild(copyhelper);
        this.props.toast('Success', 'JWT copied to clipboard', 'success')
    }

    generateJWT = async () => {
        console.log("(S2STokenGeneratorTab.generateJWT) tokens, selectedToken", this.state.tokens, this.state.selectedToken);
        let {iss, payload, endpoint, ttl, secret, kid} = this.state.selectedToken;

        let ver = parseInt(this.state.selectedToken.ver);
        const iat = Math.floor(new Date().getTime() / 1000);
        if (!iss) {
            this.props.toast("Error", "Please provide an iss.");
        } else if (![1, 2].includes(ver)) {
            this.props.toast("Error", `Please select a valid S2S version rather than ${ver}.`, "error");
        } else if (!payload) {
            this.props.toast("Error", "Please provide a payload.");
        } else if (!endpoint) {
            this.props.toast("Error", "Please provide an endpoint.");
        } else if (ttl === undefined || ttl === null || ttl < 0) {
            this.props.toast("Error", "Please provide a ttl.");
        } else if (!iat) {
            this.props.toast("Error", "Did not generate an iat.");
        } else if (!secret) {
            this.props.toast("Error", "Please provide a secret.");
        } else if (!kid) {
            this.props.toast("Error", "Please provide a Key ID.");
        } else {
            let token_payload = {
                iss,
                payload,
                endpoint,
                iat
            };

            const algorithm = VERSION_TO_ALG[ver];

            let signature = secret;
            if (["1", 1].includes(ver)) {
                signature += iat.toString();
            }

            if (!algorithm) {
                this.props.toast("Error", `Could not find alg for version ${ver}`);
                return;
            }

            const options = {
                keyid: kid,
                algorithm,
                expiresIn: parseInt(ttl),
                header: {
                    version: ver,
                    ver
                }
            }
            console.log(`(S2STokenGeneratorMasterDetailView.generateJWT) Generating token with payload ${JSON.stringify(payload)}, secret ${signature}, and options ${JSON.stringify(options)}`);
            const generatedToken = jwt.sign(token_payload, signature, options);
            this.setState({jwt: generatedToken});
            console.log(generatedToken);

            return generatedToken;
        }
    };

    switchToken = tokenId => {
        console.log("(S2STokenGeneratorTab.switchToken switching to ", tokenId);
        this.setState({
            selectedToken: this.state.tokens[tokenId],
            jwt: "",
            tokenNotSelected: false
        }, () => {
            console.log("(S2STokenGeneratorTab.switchToken switched to)", this.state.selectedToken);
        });
    };

    render() {
        return (
            <Grid divided className="masterContainer">
                <Grid.Column width={3}>
                    <S2STokenSelector
                        switchToken={this.switchToken}
                        tokens={this.state.tokens}
                        selectedToken={this.state.selectedToken}
                        createToken={this.createToken}
                    />
                </Grid.Column>
                <Grid.Column width={9} className="masterContainer">
                    <S2STokenDetailView
                        selectedToken={this.state.selectedToken}
                        updateToken={this.updateSelectedToken}
                        generateToken={this.generateJWT}
                        tokenNotSelected={this.state.tokenNotSelected}
                        toast={this.props.toast}
                        saveSelectedToken={this.saveSelectedToken}
                        deleteClicked={this.deleteClicked}
                        cloneClicked={this.cloneClicked}
                    />
                </Grid.Column>
                <Grid.Column width={4} className="detailsContainer">
                    {this.state.jwt ?
                        <div>
                            <Header as="h3" textAlign="center">{"Generated JWT"}</Header>
                            <Button onClick={this.copyClicked}>Copy</Button>
                        </div> : ""
                    }
                    <Segment basic style={{wordWrap: "break-word"}}>{this.state.jwt}</Segment>
                </Grid.Column>
            </Grid>
        )
    }
}

import React, { Component } from "react";
import {Grid, Input, TextArea, Form, Select, Dropdown} from "semantic-ui-react";
import S2STokenJSONEditor from "./S2STokenJSONEditor";
import PasswordInput from "../../PasswordInput";

export default class S2STokenDetailView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            payload: {}
        };

        this.updateSelectedToken = this.updateSelectedToken.bind(this);
        this.changePayload = this.changePayload.bind(this);
        this.saveToken = this.saveToken.bind(this);
    }

    updateSelectedToken = field => event => {
        console.log("S2STokenDetailView.updateSelectedToken: ", field, event);
        let selectedToken = this.props.selectedToken;
        selectedToken[field] = event.target.value;
        this.props.updateToken(selectedToken);
    };

    updateVersion = (event, {value}) => {
        console.log("S2STokenDetailView.updateVersion: ", event, value);
        let selectedToken = this.props.selectedToken;
        selectedToken.ver = value;
        this.props.updateToken(selectedToken);
    }

    changePayload = payload => {
        let selectedToken = this.props.selectedToken;
        selectedToken.payload = payload;
        this.props.updateToken(selectedToken);
    };

    saveToken = async () => {
        new Promise(resolve => {
            console.log("(S2STokenDetailView.saveToken) setting selectedToken.saved to true");
            if (!this.props.selectedToken.saved) {
                let selectedToken = this.props.selectedToken;
                selectedToken.saved = true;
                this.props.updateToken(selectedToken);
            }
            resolve();
        }).then(() => {
            console.log("(S2STokenDetailView.saveToken) Now calling this.props.saveSelectedToken()");
            this.props.saveSelectedToken();
        });
    };

    render() {
        return (
            <Grid width={16}>
                <Grid.Column width={16}>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-name"
                                control={Input}
                                label="Name"
                                placeholder="Token Name"
                                value={this.props.selectedToken.name}
                                onChange={this.updateSelectedToken("name")}
                                disabled={this.props.tokenNotSelected}
                            />
                            <Form.Field
                                id="form-dropdown-control-ver"
                                control={Dropdown}
                                options={[
                                    {key: "1", text: "1", value: "1"},
                                    {key: "2", text: "2", value: "2"}
                                ]}
                                label="Version"
                                placeholder="Token Version"
                                value={this.props.selectedToken.ver}
                                onChange={this.updateVersion}
                                disabled={this.props.tokenNotSelected}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-endpoint"
                                control={Input}
                                label="Endpoint"
                                placeholder="Token Path"
                                value={this.props.selectedToken.endpoint}
                                onChange={this.updateSelectedToken("endpoint")}
                                disabled={this.props.tokenNotSelected}
                            />
                            <Form.Field
                                id="form-input-control-ttl"
                                control={Input}
                                label="TTL"
                                placeholder="Token TTL"
                                value={this.props.selectedToken.ttl}
                                onChange={this.updateSelectedToken("ttl")}
                                disabled={this.props.tokenNotSelected}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-description"
                                control={TextArea}
                                label="Description"
                                placeholder="Token Description"
                                value={this.props.selectedToken.description}
                                onChange={this.updateSelectedToken("description")}
                                disabled={this.props.tokenNotSelected}
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-kid"
                                control={Input}
                                label="Key ID"
                                placeholder="Key ID"
                                value={this.props.selectedToken.kid}
                                onChange={this.updateSelectedToken("kid")}
                                disabled={this.props.tokenNotSelected}
                            />
                            {
                                this.props.selectedToken.ver !== "2" ?
                                    <Form.Field
                                        id="form-input-control-secret"
                                        control={PasswordInput}
                                        label="Secret"
                                        placeholder="Secret"
                                        value={this.props.selectedToken.secret}
                                        onChange={this.updateSelectedToken("secret")}
                                        disabled={this.props.tokenNotSelected}
                                    /> : ""
                            }
                        </Form.Group>
                        {
                            this.props.selectedToken.ver === "2" ?
                                <Form.Field
                                    id="form-textarea-control-private-key"
                                    control={TextArea}
                                    label="Private Key"
                                    value={this.props.selectedToken.secret}
                                    onChange={this.updateSelectedToken("secret")}
                                    disabled={this.props.tokenNotSelected}
                                /> : ""
                        }
                        <Form.Group widths="equal">
                            <Form.Field
                                id="form-input-control-iss"
                                control={Input}
                                label="iss"
                                placeholder="Source App ID"
                                value={this.props.selectedToken.iss}
                                onChange={this.updateSelectedToken("iss")}
                                disabled={this.props.tokenNotSelected}
                            />
                        </Form.Group>
                    </Form>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <S2STokenJSONEditor
                                payload={this.props.selectedToken.payload}
                                updatePayload={this.changePayload}
                                lastNodeValue={this.state.currentEditorText}
                                disableInitialLoad={this.disableInitialLoad}
                                initialLoad={this.state.initialLoad}
                                generateToken={this.props.generateToken}
                                disabled={this.props.tokenNotSelected}
                                toast={this.props.toast}
                                saveToken={this.saveToken}
                                deleteClicked={this.props.deleteClicked}
                                cloneClicked={this.props.cloneClicked}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        )
    }
}

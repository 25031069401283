import React, {Component} from "react";
import { Grid, Button } from "semantic-ui-react";
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'

export default class S2STokenJSONEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentEditorText: {},
            lastEditorText: {},
        };

        this.handleReset = this.handleReset.bind(this);
        this.generateClicked = this.generateClicked.bind(this);
    }

    handleReset = () => {
        this.setState({currentEditorText: this.state.lastEditorText});
        this.jsoneditor.set(this.state.lastEditorText);
    }

    componentDidMount = () => {
        console.log(`(S2STokenJSONEditor componentDidMount) Started.`);
        const options = {
            mode: 'code'
        };
        this.jsoneditor = new JSONEditor(this.container, options);
        if(this.props.initialLoad) {
            this.jsoneditor.set(this.state.currentEditorText);
            this.props.disableInitialLoad();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(`(S2STokenJSONEditor componentDidUpdate) Started.`, prevProps, prevState, this.props, this.state);
        if (this.props.payload !== prevProps.payload) {
            this.setState({currentEditorText: this.props.payload}, () => {
                this.jsoneditor.set(this.state.currentEditorText);
            });
        }
    }

    generateClicked = () => {
        try {
            console.log("S2STokenJSONEditor.generateClicked jsoneditor.get(): ", this.jsoneditor.get());
            this.props.updatePayload(this.jsoneditor.get());
            this.props.generateToken();
            this.setState({lastEditorText: this.state.currentEditorText});
        } catch (error) {
            console.log("S2STokenJSONEditor.generateClicked invalid json payload: ", error)
            this.props.toast("Error", "Invalid JSON payload", "error");
        }
    }

    render() {
        return (
            <Grid.Column width={16}>
                <Grid.Row>
                    <div style={{ height: '16em' }} ref={elem => this.container = elem} />
                </Grid.Row>
                <Grid.Row />
                <Grid.Row style={{paddingTop: '1em'}}>
                    <Button
                        primary
                        onClick={this.generateClicked}
                        style={{marginRight: '1em'}}
                    >Generate</Button>
                    <Button
                        primary
                        onClick={this.props.cloneClicked}
                        style={{marginRight: '1em'}}
                    >Clone</Button>
                    <Button
                        onClick={this.handleReset}
                        color="yellow"
                    >Reset</Button>
                    <Button
                        primary
                        onClick={this.props.saveToken}
                    >Save</Button>
                    <Button
                        secondary
                        onClick={this.props.deleteClicked}
                        floated="right"
                        color="red"
                    >Delete</Button>
                </Grid.Row>
            </Grid.Column>
        );
    }
}
